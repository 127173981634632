import { all, call, put, takeLatest } from "redux-saga/effects";
import { ActionTypesEnum } from "./types.enum";
import { getCompanyProductsByCompanyIdHelper } from "../../helpers/backend_helper";
import { ICompanyProductsInitialState } from "./state";
import { changeCompanyProducts } from "./action";

function* getCompanyProductsByCompanyIdSaga({ payload }: any) {
    try {
      const companyProducts: ICompanyProductsInitialState = yield call(getCompanyProductsByCompanyIdHelper, { companyId: payload })
      yield put(changeCompanyProducts(companyProducts))
    } catch {
      // asdasda
    } 
  }
  export function* watchAll() {
    yield takeLatest(ActionTypesEnum.FETCH_COMPANY_PRODUCTS, getCompanyProductsByCompanyIdSaga);
  }
  
  function* companyProductsSaga() {
    yield all([
      call(watchAll)
    ]);
  }
  export default companyProductsSaga 