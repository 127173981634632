/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import classnames from "classnames";
import ReportsTable from "./ReportTable";
import ReportFilter from "./ReportFilter";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignEliminatedReport,
  getCampaignMediationReport,
  getCampaignNewReport,
  getCampaignNotifiedReport,
  getCampaignReappearedReport,
  getCampaignReport,
  setRangeDate,
  setReportEndDate,
  setReportStartDate,
  setTab,
} from "../../../store/pages/reports/actions";
import { useTranslation } from "react-i18next";
import CampaignSelector from "../../../Components/Common/CampaignSelector";
import moment from "moment-timezone";
import { hasOneElementAtLeast } from "../../../utils/arrayFunctions";
import BouncingDotsLoader from "../../../Components/Common/BoucingDotsLoader";
import { use } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CHANNEL_OPTIONS,
  ACTINGS_OPTIONS,
  PAGE,
  STATUS_OPTIONS,
} from "./optionsValues";
import {
  setSelectedCompany,
  setSelectedCampaign,
} from "../../../store/actions";
import { TABS_MAP } from "../../../utils/tabsMap";
import {
  normalizeStartDate,
  normalizeEndDate,
} from "../../../utils/normalizeEndDate";

let DAYS_ALL = 0;
const DAYS_7 = 7;
const DAYS_14 = 14;
const DAYS_28 = 28;
const MAX_DATE = moment()
  .subtract(1, "days")
  .endOf("day")
  .tz("America/Sao_Paulo")
  .toDate();

const getStatusOptionByValue = (value, optionsArr) => {
  if (!optionsArr) {
    return;
  }
  return optionsArr.find(
    (option) => option.value.toLowerCase() === value.toLowerCase()
  );
};

const Starter = () => {
  document.title = "Branddi.com - BrandBidding | Gerenciar Agressores";
  document.description = "Combata o uso indevido da sua marca e maximize seus resultados";
  const body = document.body;

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(location.search);

  const firstRenderOfReports = useRef(true);
  const firstRenderOfFilters = useRef(true);

  const selectProfileState = (state) => state.Profile;
  const { user, selectedCampaign, selectedCompany } =
    useSelector(selectProfileState);

  const selectReportState = (state) => state.Report;
  const {
    reportCampaignId,
    positionsFilterOptions,
    reportStartDate,
    reportEndDate,
    totalAggressorsReport,
    newAggressorsReport,
    notifiedAggressorsReport,
    mediationAggressorsReport,
    reappearedAggressorsReport,
    eliminatedAggressorsReport,
    selectedTab,
    selectedRangeDate,
  } = useSelector(selectReportState);

  const selectPanelState = (state) => state.Panel;
  const { panelLoading } = useSelector(selectPanelState);

  const channel = searchParams.get("channel");
  const selectedChannel = channel
    ? { value: channel, label: channel }
    : { value: "", label: "Todos" };
  const [selectedChannelFilter, setSelectedChannelFilter] =
    useState(selectedChannel);

  const actualPahse = searchParams.get("actualPhase");
  const actualPahseSelected = actualPahse
    ? {
        value: actualPahse,
        label: STATUS_OPTIONS.find((e) => e.value === actualPahse).label,
      }
    : { value: "", label: "Todos" };
  const [selectedStatusFilter, setSelectedStatusFilter] =
    useState(actualPahseSelected);

  const agressor = searchParams.get("agressor");
  const [selectedDomainFilter, setSelectedDomainFilter] = useState(agressor);

  const keywordOptions = selectedCampaign?.keywords?.map((keyword) => ({
    value: keyword,
    label: keyword,
  }));

  const keywordUSearchParam = searchParams.get("keyword") || "";
  const keywords = keywordUSearchParam
    .split(",")
    .map((item) => item.trim())
    .filter((item) => item !== "");
  const keywordFilterCopy = useMemo(() => {
    const filterCopy = [];
    if (keywords.length > 0) {
      keywords.forEach((element) => {
        const option = getStatusOptionByValue(element, keywordOptions);
        if (option) {
          filterCopy.push(option);
        }
      });
    }
    return filterCopy;
  }, [keywords, keywordOptions]);
  const [selectedKeywordFilter, setSelectedKeywordFilter] = useState(
    keywordFilterCopy || []
  );

  const pageOptions = PAGE.concat(
    selectedCampaign?.pages?.map((page) => ({
      value: page.toString(),
      label: `Página ${page.toString()}`,
    }))
  );
  const page = searchParams.get("page");
  const pageSelected = getStatusOptionByValue(page, pageOptions) || {
    value: "",
    label: "Todas",
  };
  const [selectedPageFilter, setSelectedPageFilter] = useState(pageSelected);

  const notify = searchParams.get("notify");
  const notifySelected = getStatusOptionByValue(notify, ACTINGS_OPTIONS) || {
    value: "",
    label: "Todas",
  };
  const [selectedActingFilter, setSelectedActingFilter] =
    useState(notifySelected);

  const positionSearchParam = searchParams.get("position");
  const positions = positionSearchParam
    ? positionSearchParam
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item !== "")
    : [];
  const selectedFilters =
    positions.length > 0
      ? positions.map((element) => ({
          value: element,
          label: `Posição ${element}`,
        }))
      : [];
  const [selectedPositionFilter, setSelectedPositionFilter] =
    useState(selectedFilters);

  const flatpickrStartDate = useRef(null);
  const flatpickrEndDate = useRef(null);

  const days = searchParams.get("days");
  const [selectedPeriod, setSelectedPeriod] = useState(parseInt(days));

  const [showSelectedPeriod, setShowSelectedPeriod] = useState(true);

  const [datesFilter, setDatesFilter] = useState([
    reportStartDate,
    reportEndDate,
  ]);

  const query = new URLSearchParams(useLocation().search);

  const companyId = searchParams.get("companyId");
  const campaignId = searchParams.get("campaignId");
  const INITIAL_DATE = searchParams.get("startDate")
    ? new Date(searchParams.get("startDate"))
    : moment().subtract(14, "days").startOf("day").toDate();
  const FINAL_DATE = searchParams.get("endDate")
    ? new Date(searchParams.get("endDate"))
    : moment().subtract(1, "days").endOf("day").toDate();

  useEffect(() => {
    dispatch(setReportStartDate(INITIAL_DATE));
    dispatch(setReportEndDate(FINAL_DATE));
  }, []);

  useEffect(() => {
    if (user.companies) {
      const selectedCompany = user.companies.find(
        (item) => item._id === companyId
      );
      if (!selectedCompany) {
        return;
      }
      dispatch(
        setSelectedCompany({
          value: selectedCompany._id,
          label: selectedCompany.name,
        })
      );
    }
  }, [companyId, user.companies]);

  useEffect(() => {
    if (user.campaigns) {
      const campaign = user.campaigns?.find(
        (campaign) => campaign._id === campaignId
      );
      if (!campaign) {
        return;
      }
      dispatch(
        setSelectedCampaign({
          value: campaign?._id,
          label: campaign?.name,
          createdAt: campaign?.createdAt,
          keywords: campaign?.keywords,
          urlLogo: campaign?.urlLogo,
          pages: campaign?.pages,
        })
      );
    }
  }, [campaignId, user.campaigns]);

  // Pills Justified Tabs
  const tab = searchParams.get("tab") || "identificados";
  const [justifyPillsTab, setjustifyPillsTab] = useState(TABS_MAP[tab]);

  const justifyPillsToggle = (tab) => {
    dispatch(setTab(tab));
    setjustifyPillsTab(tab);

    const tabKey = Object.keys(TABS_MAP).find((key) => TABS_MAP[key] === tab);
    if (tabKey) {
      searchParams.set("tab", tabKey);
      const newSearch = searchParams.toString();
      if (location.search !== `?${newSearch}`) {
        navigate({ search: newSearch });
      }
    }
  };

  const channelOptions = CHANNEL_OPTIONS.concat(
    totalAggressorsReport?.data
      ?.map((report) =>
        report?.channel?.map((channel) => ({
          value: channel,
          label: channel,
        }))
      )
      .flat() || []
  );

  /**Update the searchParams with state value*/

  useEffect(() => {
    if (reportStartDate) searchParams.set("startDate", reportStartDate);
    if (reportEndDate) searchParams.set("endDate", reportEndDate);

    const newSearch = searchParams.toString();
    if (location.search !== `?${newSearch}`) {
      navigate({ search: newSearch });
    }
  }, [reportStartDate, reportEndDate, navigate, location.search]);

  const updateDaysSearchParam = (selectedPeriod) => {
    searchParams.set("days", selectedPeriod);
    const newSearch = searchParams.toString();
    if (location.search !== `?${newSearch}`) {
      navigate({ search: newSearch });
    }
  };

  useEffect(() => {
    if (selectedChannelFilter) {
      searchParams.set("channel", selectedChannelFilter.value);
      const newSearch = searchParams.toString();
      if (location.search !== `?${newSearch}`) {
        navigate({ search: newSearch });
      }
    }
  }, [selectedChannelFilter, navigate, location.search]);

  useEffect(() => {
    if (selectedStatusFilter) {
      searchParams.set("actualPhase", selectedStatusFilter.value);
      const newSearch = searchParams.toString();
      if (location.search !== `?${newSearch}`) {
        navigate({ search: newSearch });
      }
    }
  }, [selectedStatusFilter, navigate, location.search]);

  useEffect(() => {
    if (selectedActingFilter) {
      searchParams.set("notify", selectedActingFilter.value);
      const newSearch = searchParams.toString();
      if (location.search !== `?${newSearch}`) {
        navigate({ search: newSearch });
      }
    }
  }, [selectedActingFilter, navigate, location.search]);

  useEffect(() => {
    if (selectedPageFilter) {
      searchParams.set("page", selectedPageFilter.value);
      const newSearch = searchParams.toString();
      if (location.search !== `?${newSearch}`) {
        navigate({ search: newSearch });
      }
    }
  }, [selectedPageFilter, navigate, location.search]);

  useEffect(() => {
    searchParams.set("agressor", selectedDomainFilter);
    const newSearch = searchParams.toString();
    if (location.search !== `?${newSearch}`) {
      navigate({ search: newSearch });
    }
  }, [selectedDomainFilter, navigate, location.search]);

  useEffect(() => {
    if (keywordOptions) {
      const keywordParamValue = selectedKeywordFilter
        .map((item) => item.value)
        .join(",");
      if (keywordParamValue) {
        searchParams.set("keyword", keywordParamValue);
      } else {
        searchParams.delete("keyword");
      }

      const newSearch = searchParams.toString();
      if (location.search !== `?${newSearch}`) {
        navigate({ search: newSearch });
      }
    }
  }, [selectedKeywordFilter, navigate, searchParams, location.search]);

  useEffect(() => {
    if (positionsFilterOptions) {
      const positionParamValue = selectedPositionFilter
        .map((item) => item.value)
        .join(",");
      if (positionParamValue) {
        searchParams.set("position", positionParamValue);
      } else {
        searchParams.delete("position");
      }

      // Atualiza a URL somente se for necessário
      const newSearch = searchParams.toString();
      if (location.search !== `?${newSearch}`) {
        navigate({ search: newSearch });
      }
    }
  }, [
    selectedPositionFilter,
    positionsFilterOptions,
    navigate,
    searchParams,
    location.search,
  ]);

  // Calendar
  const onSelectedPeriodChange = (totalDaysSelected) => {
    if (!selectedCampaign?.createdAt) {
      return;
    }

    const campaignCreatedDate = moment().diff(
      new Date(moment(selectedCampaign?.createdAt)),
      "days"
    );

    const totalDays =
      totalDaysSelected === DAYS_ALL ? campaignCreatedDate : totalDaysSelected;
    DAYS_ALL = totalDaysSelected === DAYS_ALL ? totalDays : DAYS_ALL;

    const initialDate = new Date(
      moment()
        .subtract(totalDays, "days")
        .startOf("day")
        .tz("America/Sao_Paulo")
    );

    const currentDate = new Date();
    const finalDate = new Date(currentDate.setDate(currentDate.getDate() - 1));

    if (flatpickrStartDate.current && flatpickrStartDate.current.flatpickr) {
      flatpickrStartDate.current.flatpickr.setDate(initialDate, true);
    }

    if (flatpickrEndDate.current && flatpickrEndDate.current.flatpickr) {
      flatpickrEndDate.current.flatpickr.setDate(finalDate, true);
    }

    dispatch(setReportStartDate(initialDate));
    dispatch(setReportEndDate(finalDate));
    dispatch(setRangeDate(String(totalDays)));

    setSelectedPeriod(totalDays);
    updateDaysSearchParam(totalDays);
  };

  const handleStartDateChange = (startDateSelected) => {
    if (reportEndDate === null) return;

    const isEndDateBeforeStartDate =
      moment(startDateSelected).isAfter(reportEndDate);

    if (isEndDateBeforeStartDate) {
      dispatch(
        setReportEndDate(moment(startDateSelected).endOf("day").format())
      );
      flatpickrEndDate.current.flatpickr.setDate(startDateSelected, true);
    }

    dispatch(
      setReportStartDate(moment(startDateSelected).startOf("day").format())
    );
  };

  const handleEndDateChange = (endDateSelected) => {
    if (reportStartDate === null) return;

    const isEndDateAfterStartDate =
      moment(endDateSelected).isBefore(reportStartDate);

    if (isEndDateAfterStartDate) {
      dispatch(
        setReportStartDate(moment(endDateSelected).startOf("day").format())
      );
      flatpickrStartDate.current.flatpickr.setDate(endDateSelected, true);
    }

    dispatch(setReportEndDate(moment(endDateSelected).endOf("day").format()));
  };

  useEffect(() => {
    console.log(selectedPeriod);
    if (selectedPeriod !== parseInt(selectedRangeDate)) {
      console.log(selectedPeriod);

      onSelectedPeriodChange(selectedPeriod);
    }
  }, [selectedPeriod]);

  // Toggle Filter
  const [statusFilter, setStatusFilter] = useState(false);
  const toggleFilter = () => {
    body.style.overflow = !statusFilter ? "hidden" : "";
    setStatusFilter(!statusFilter);
  };

  const handleSelectedChannelFilterChange = (value) => {
    setSelectedChannelFilter(value);
  };
  const handleSelectedStatusFilterChange = (value) => {
    setSelectedStatusFilter(value);
  };
  const handleSelectedDomainFilterChange = (value) => {
    setSelectedDomainFilter(value);
  };

  const handleSelectedKeywordFilterChange = (value) => {
    setSelectedKeywordFilter(value);
  };

  const handleSelectedPageFilterChange = (value) => {
    setSelectedPageFilter(value);
  };

  const handleSelectedPositionFilterChange = (value) => {
    setSelectedPositionFilter(value);
  };
  const handleSelectedActingFilterChange = (value) => {
    setSelectedActingFilter(value);
  };

  const handleFiltersCombination = (filteredArray) => {
    if (!Array.isArray(filteredArray)) return [];

    let resultArray = [...filteredArray];

    // Filtro de canal
    if (selectedChannelFilter.value) {
      resultArray = resultArray.filter(
        (item) =>
          item.channel && item.channel.includes(selectedChannelFilter.value)
      );
    }

    // Filtro de status
    if (selectedStatusFilter.value) {
      resultArray = resultArray.filter(
        (item) =>
          item.status && item.status.includes(selectedStatusFilter.value)
      );
    }

    // Filtro de domínio
    if (selectedDomainFilter) {
      resultArray = resultArray.filter(
        (item) => item.domain && item.domain.includes(selectedDomainFilter)
      );
    }

    // Filtro de palavras-chave
    if (selectedKeywordFilter.length) {
      const keywordValues = selectedKeywordFilter.map(
        (keyword) => keyword.value
      );
      resultArray = resultArray.filter(
        (item) =>
          item.keyword && hasOneElementAtLeast(item.keyword, keywordValues)
      );
    }

    // Filtro de ação
    if (selectedActingFilter.value) {
      resultArray = resultArray.filter(
        (item) =>
          item.acting && item.acting.includes(selectedActingFilter.value)
      );
    }

    // Filtro de página
    if (selectedPageFilter.value === "1") {
      resultArray = resultArray.filter(
        (item) => item.firstPage && item.firstPage.length > 0
      );
    } else if (selectedPageFilter.value === "2") {
      resultArray = resultArray.filter(
        (item) => item.secondPage && item.secondPage.length > 0
      );
    }

    // Filtro de posição
    if (selectedPositionFilter.length) {
      const positionValues = selectedPositionFilter.map(
        (position) => position.value
      );

      if (selectedPageFilter.value === "1") {
        resultArray = resultArray.filter(
          (item) =>
            item.firstPage &&
            hasOneElementAtLeast(item.firstPage, positionValues)
        );
      } else if (selectedPageFilter.value === "2") {
        resultArray = resultArray.filter(
          (item) =>
            item.secondPage &&
            hasOneElementAtLeast(item.secondPage, positionValues)
        );
      } else {
        resultArray = resultArray.filter(
          (item) =>
            item.firstPage &&
            item.secondPage &&
            hasOneElementAtLeast(
              item.firstPage.concat(item.secondPage),
              positionValues
            )
        );
      }
    }

    return resultArray;
  };

  const handleFilteredData = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return handleFiltersCombination(data);
  };

  const totalAggressorsReportFiltered = useMemo(() => {
    return handleFilteredData(totalAggressorsReport.data);
  }, [
    totalAggressorsReport.data,
    selectedChannelFilter,
    selectedStatusFilter,
    selectedDomainFilter,
    selectedKeywordFilter,
    selectedPageFilter,
    selectedPositionFilter,
    selectedActingFilter,
  ]);

  const newAggressorsReportFiltered = useMemo(() => {
    return handleFilteredData(newAggressorsReport.data);
  }, [
    newAggressorsReport.data,
    selectedChannelFilter,
    selectedStatusFilter,
    selectedDomainFilter,
    selectedKeywordFilter,
    selectedPageFilter,
    selectedPositionFilter,
  ]);

  const reappearedAggressorsReportFiltered = useMemo(() => {
    return handleFilteredData(reappearedAggressorsReport.data);
  }, [
    reappearedAggressorsReport.data,
    selectedChannelFilter,
    selectedStatusFilter,
    selectedDomainFilter,
    selectedKeywordFilter,
    selectedPageFilter,
    selectedPositionFilter,
  ]);

  const notifiedAggressorsReportFiltered = useMemo(() => {
    return handleFilteredData(notifiedAggressorsReport.data);
  }, [
    notifiedAggressorsReport.data,
    selectedChannelFilter,
    selectedDomainFilter,
    selectedStatusFilter,
    selectedKeywordFilter,
    selectedPageFilter,
    selectedPositionFilter,
  ]);

  const mediationAggressorsReportFiltered = useMemo(() => {
    return handleFilteredData(mediationAggressorsReport.data);
  }, [
    mediationAggressorsReport.data,
    selectedChannelFilter,
    selectedDomainFilter,
    selectedStatusFilter,
    selectedKeywordFilter,
    selectedPageFilter,
    selectedPositionFilter,
  ]);

  const eliminatedAggressorsReportFiltered = useMemo(() => {
    return handleFilteredData(eliminatedAggressorsReport.data);
  }, [
    eliminatedAggressorsReport.data,
    selectedChannelFilter,
    selectedStatusFilter,
    selectedDomainFilter,
    selectedKeywordFilter,
    selectedPageFilter,
    selectedPositionFilter,
  ]);

  useEffect(() => {
    if (firstRenderOfFilters.current) {
      firstRenderOfFilters.current = false;
    }
  }, [selectedCampaign, selectedCompany]);

  useEffect(() => {
    if (datesFilter.length >= 1) {
      if (firstRenderOfReports.current) {
        (totalAggressorsReport.data === null ||
          reportCampaignId !== selectedCampaign.value) &&
          dispatch(
            getCampaignReport(
              selectedCampaign.value,
              normalizeStartDate(reportStartDate || INITIAL_DATE),
              normalizeEndDate(reportEndDate || FINAL_DATE),
              1
            )
          );

        (newAggressorsReport.data === null ||
          reportCampaignId !== selectedCampaign.value) &&
          dispatch(
            getCampaignNewReport(
              selectedCampaign.value,
              normalizeStartDate(reportStartDate || INITIAL_DATE),
              normalizeEndDate(reportEndDate || FINAL_DATE),
              1
            )
          );

        (notifiedAggressorsReport.data === null ||
          reportCampaignId !== selectedCampaign.value) &&
          dispatch(
            getCampaignNotifiedReport(
              selectedCampaign.value,
              normalizeStartDate(reportStartDate || INITIAL_DATE),
              normalizeEndDate(reportEndDate || FINAL_DATE),
              1
            )
          );

        (mediationAggressorsReport.data === null ||
          reportCampaignId !== selectedCampaign.value) &&
          dispatch(
            getCampaignMediationReport(
              selectedCampaign.value,
              normalizeStartDate(reportStartDate || INITIAL_DATE),
              normalizeEndDate(reportEndDate || FINAL_DATE),
              1
            )
          );
        firstRenderOfReports.current = false;

        (reappearedAggressorsReport.data === null ||
          reportCampaignId !== selectedCampaign.value) &&
          dispatch(
            getCampaignReappearedReport(
              selectedCampaign.value,
              normalizeStartDate(reportStartDate || INITIAL_DATE),
              normalizeEndDate(reportEndDate || FINAL_DATE),
              1
            )
          );
        firstRenderOfReports.current = false;

        (eliminatedAggressorsReport.data === null ||
          reportCampaignId !== selectedCampaign.value) &&
          dispatch(
            getCampaignEliminatedReport(
              selectedCampaign.value,
              normalizeStartDate(reportStartDate || INITIAL_DATE),
              normalizeEndDate(reportEndDate || FINAL_DATE),
              1
            )
          );
        firstRenderOfReports.current = false;
      } else {
        dispatch(
          getCampaignReport(
            selectedCampaign.value,
            normalizeStartDate(reportStartDate || INITIAL_DATE),
            normalizeEndDate(reportEndDate || FINAL_DATE),
            1
          )
        );
        dispatch(
          getCampaignNewReport(
            selectedCampaign.value,
            normalizeStartDate(reportStartDate || INITIAL_DATE),
            normalizeEndDate(reportEndDate || FINAL_DATE),
            1
          )
        );
        dispatch(
          getCampaignNotifiedReport(
            selectedCampaign.value,
            normalizeStartDate(reportStartDate || INITIAL_DATE),
            normalizeEndDate(reportEndDate || FINAL_DATE),
            1
          )
        );
        dispatch(
          getCampaignMediationReport(
            selectedCampaign.value,
            normalizeStartDate(reportStartDate || INITIAL_DATE),
            normalizeEndDate(reportEndDate || FINAL_DATE),
            1
          )
        );
        dispatch(
          getCampaignReappearedReport(
            selectedCampaign.value,
            normalizeStartDate(reportStartDate || INITIAL_DATE),
            normalizeEndDate(reportEndDate || FINAL_DATE),
            1
          )
        );
        dispatch(
          getCampaignEliminatedReport(
            selectedCampaign.value,
            normalizeStartDate(reportStartDate || INITIAL_DATE),
            normalizeEndDate(reportEndDate || FINAL_DATE),
            1
          )
        );
      }
    }
  }, [reportStartDate, reportEndDate, dispatch, selectedCampaign]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            path={[
              { title: "Brand Bidding", link: "/" },
              { title: "   Agressores", link: "" },
            ]}
          />
          <Row className="d-flex justify-content-end mb-3">
            <Col>
              <CampaignSelector
                panelLoading={panelLoading}
                user={user}
                selectedCampaign={selectedCampaign}
                selectedCompany={selectedCompany}
              />
            </Col>

            <Col md="5">
              {panelLoading ? (
                <div className="d-flex align-items-center justify-content-center form-control dash-filter-picker ms-3">
                  {" "}
                  <BouncingDotsLoader size={"2px"} />{" "}
                </div>
              ) : (
                <div className="d-flex gap-1">
                  <div className="input-group d-flex">
                    <Flatpickr
                      className="form-control border-0 shadow pe-auto"
                      options={{
                        dateFormat: "d M, Y",
                        locale: Portuguese,
                        value: reportStartDate,
                        defaultDate: reportStartDate
                          ? new Date(reportStartDate)
                          : INITIAL_DATE,
                        maxDate: MAX_DATE,
                        disableMobile: "true",
                      }}
                      onChange={(date) => {
                        const newDate = date[0];
                        if (newDate !== reportStartDate) {
                          setDatesFilter(date);
                          handleStartDateChange(newDate);
                        }
                      }}
                      ref={flatpickrStartDate}
                    />

                    <Flatpickr
                      className="form-control border-0 shadow pe-auto"
                      options={{
                        dateFormat: "d M, Y",
                        locale: Portuguese,
                        value: reportEndDate,
                        defaultDate: reportEndDate
                          ? new Date(reportEndDate)
                          : new Date(FINAL_DATE),
                        maxDate: MAX_DATE,
                        disableMobile: "true",
                      }}
                      onChange={(date) => {
                        const newDate = date[0];
                        if (newDate !== reportEndDate) {
                          setDatesFilter(date);
                          handleEndDateChange(newDate);
                        }
                      }}
                      ref={flatpickrEndDate}
                    />
                    <button className="btn btn-primary text-white pe-none">
                      <i className="ri-calendar-2-line"></i>
                    </button>
                  </div>

                  <div className="d-flex gap-1">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedPeriod(DAYS_7);
                        setShowSelectedPeriod(true);
                      }}
                      className={
                        showSelectedPeriod && selectedPeriod === DAYS_7
                          ? "btn btn-soft-primary btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                    >
                      7D
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedPeriod(DAYS_14);
                        setShowSelectedPeriod(true);
                      }}
                      className={
                        showSelectedPeriod && selectedPeriod === DAYS_14
                          ? "btn btn-soft-primary btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                    >
                      14D
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedPeriod(DAYS_28);
                        setShowSelectedPeriod(true);
                      }}
                      className={
                        showSelectedPeriod && selectedPeriod === DAYS_28
                          ? "btn btn-soft-primary btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                    >
                      28D
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedPeriod(DAYS_ALL);
                        setShowSelectedPeriod(true);
                      }}
                      className={
                        selectedPeriod === DAYS_ALL
                          ? "btn btn-soft-primary btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                    >
                      Tudo
                    </button>
                  </div>
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Tickets abertos para agressores
                  </h4>
                  <button
                    type="button"
                    className="btn p-0 btn-open-filter"
                    onClick={toggleFilter}
                  >
                    <i className="bx bx-filter-alt fs-20"></i>
                  </button>
                </CardHeader>

                <CardBody className="p-0">
                  <ReportFilter
                    panelLoading={panelLoading}
                    positionsFilterOptions={positionsFilterOptions}
                    statusFilter={statusFilter}
                    hideStatusFilter={toggleFilter}
                    handleSelectedChannelFilterChange={
                      handleSelectedChannelFilterChange
                    }
                    totalAggressorsReport={totalAggressorsReport}
                    handleSelectedStatusFilterChange={
                      handleSelectedStatusFilterChange
                    }
                    selectedChannelFilter={selectedChannelFilter}
                    selectedStatusFilter={selectedStatusFilter}
                    selectedDomainFilter={selectedDomainFilter}
                    handleSelectedDomainFilterChange={
                      handleSelectedDomainFilterChange
                    }
                    selectedKeywordFilter={selectedKeywordFilter}
                    handleSelectedKeywordFilterChange={
                      handleSelectedKeywordFilterChange
                    }
                    selectedPageFilter={selectedPageFilter}
                    handleSelectedPageFilterChange={
                      handleSelectedPageFilterChange
                    }
                    selectedPositionFilter={selectedPositionFilter}
                    handleSelectedPositionFilterChange={
                      handleSelectedPositionFilterChange
                    }
                    handleSelectedActingFilterChange={
                      handleSelectedActingFilterChange
                    }
                    selectedActingFilter={selectedActingFilter}
                    selectedCampaign={selectedCampaign}
                  />

                  <div className="tabs-custom">
                    <Nav className="nav nav-tabs nav-tabs-custom nav-primary px-3 pt-3 pb-0">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames(
                            { active: justifyPillsTab === "1" },
                            "text-primary d-flex align-items-center justify-content-center"
                          )}
                          onClick={() => {
                            justifyPillsToggle("1");
                          }}
                        >
                          <span className="me-1">Identificados</span>{" "}
                          {totalAggressorsReport.loading ? (
                            <Spinner size={"sm"} />
                          ) : (
                            <span className="badge bg-danger-subtle text-danger rounded-circle badge-custom">
                              {totalAggressorsReportFiltered?.length}
                            </span>
                          )}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames(
                            { active: justifyPillsTab === "2" },
                            "text-primary d-flex align-items-center justify-content-center"
                          )}
                          onClick={() => {
                            justifyPillsToggle("2");
                          }}
                        >
                          <span className="me-1">Novos</span>{" "}
                          {newAggressorsReport.loading ? (
                            <Spinner size={"sm"} />
                          ) : (
                            <span className="badge bg-danger-subtle text-danger rounded-circle badge-custom">
                              {newAggressorsReportFiltered?.length}
                            </span>
                          )}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames(
                            { active: justifyPillsTab === "3" },
                            "text-primary"
                          )}
                          onClick={() => {
                            justifyPillsToggle("3");
                          }}
                        >
                          <span className="me-1">Reincidentes</span>{" "}
                          {reappearedAggressorsReport.loading ? (
                            <Spinner size={"sm"} />
                          ) : (
                            <span className="badge bg-danger-subtle text-danger rounded-circle badge-custom">
                              {reappearedAggressorsReportFiltered?.length}
                            </span>
                          )}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames(
                            { active: justifyPillsTab === "4" },
                            "text-primary"
                          )}
                          onClick={() => {
                            justifyPillsToggle("4");
                          }}
                        >
                          <span className="me-1">Notificados</span>{" "}
                          {notifiedAggressorsReport.loading ? (
                            <Spinner size={"sm"} />
                          ) : (
                            <span className="badge bg-danger-subtle text-danger rounded-circle badge-custom">
                              {notifiedAggressorsReportFiltered?.length}
                            </span>
                          )}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames(
                            { active: justifyPillsTab === "5" },
                            "text-primary"
                          )}
                          onClick={() => {
                            justifyPillsToggle("5");
                          }}
                        >
                          <span className="me-1">Em Mediação</span>{" "}
                          {mediationAggressorsReport.loading ? (
                            <Spinner size={"sm"} />
                          ) : (
                            <span className="badge bg-danger-subtle text-danger rounded-circle badge-custom">
                              {mediationAggressorsReportFiltered?.length}
                            </span>
                          )}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames(
                            { active: justifyPillsTab === "6" },
                            "text-primary"
                          )}
                          onClick={() => {
                            justifyPillsToggle("6");
                          }}
                        >
                          <span className="me-1">Inativos</span>{" "}
                          {eliminatedAggressorsReport.loading ? (
                            <Spinner size={"sm"} />
                          ) : (
                            <span className="badge bg-success-subtle text-success rounded-circle badge-custom">
                              {eliminatedAggressorsReportFiltered?.length}
                            </span>
                          )}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>

                  <TabContent
                    activeTab={justifyPillsTab}
                    className="text-muted"
                  >
                    <TabPane tabId="1" id="pill-justified-home-1">
                      <ReportsTable
                        reportData={totalAggressorsReportFiltered}
                        reportLoading={totalAggressorsReport.loading}
                        reportError={totalAggressorsReport.error}
                      />
                    </TabPane>

                    <TabPane tabId="2" id="pill-justified-settings-1">
                      <ReportsTable
                        reportData={newAggressorsReportFiltered}
                        reportLoading={newAggressorsReport.loading}
                        reportError={newAggressorsReport.error}
                      />
                    </TabPane>

                    <TabPane tabId="3" id="pill-justified-settings-1">
                      <ReportsTable
                        reportData={reappearedAggressorsReportFiltered}
                        reportLoading={reappearedAggressorsReport.loading}
                        reportError={reappearedAggressorsReport.error}
                      />
                    </TabPane>

                    <TabPane tabId="4" id="pill-justified-settings-1">
                      <ReportsTable
                        reportData={notifiedAggressorsReportFiltered}
                        reportLoading={notifiedAggressorsReport.loading}
                        reportError={notifiedAggressorsReport.error}
                      />
                    </TabPane>

                    <TabPane tabId="5" id="pill-justified-settings-1">
                      <ReportsTable
                        reportData={mediationAggressorsReportFiltered}
                        reportLoading={mediationAggressorsReport.loading}
                        reportError={mediationAggressorsReport.error}
                      />
                    </TabPane>

                    <TabPane tabId="6" id="pill-justified-settings-1">
                      <ReportsTable
                        reportData={eliminatedAggressorsReportFiltered}
                        reportLoading={eliminatedAggressorsReport.loading}
                        reportError={eliminatedAggressorsReport.error}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Starter;
