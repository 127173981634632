import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { Card, CardBody, CardHeader } from "reactstrap";
import BouncingDotsLoader from "../../../Components/Common/BoucingDotsLoader";

const AnalyticalReportTotalAggressiveness = ({
  dailyTotalAggression,
  dailyTotalAggressionLoading,
  startDate,
  endDate,
}) => {
  var chartColor = getChartColorsArray(
    '["--vz-secondary", "--vz-card-custom"]'
  );

  let outputTotalAggression;

  const groupedData = dailyTotalAggression.reduce((acc, obj) => {
    const date = new Date(obj.processedDate);
    const dateString = date.toISOString().split("T")[0];

    if (!acc[dateString]) {
      acc[dateString] = {
        _id: dateString,
        totalAggression: 0,
        campaignId: obj.campaignId,
        count: 0,
      };
    }

    acc[dateString].totalAggression += obj.aggressionScoreFull;
    acc[dateString].count += 1;

    return acc;
  }, {});

  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  const allDates = getDates(new Date(startDate), new Date(endDate));

  allDates.forEach((date) => {
    const dateString = date.toISOString().split("T")[0];
    if (!groupedData[dateString]) {
      groupedData[dateString] = {
        _id: dateString,
        totalAggression: 0,
        campaignId: null,
        count: 0,
      };
    }
  });

  outputTotalAggression = Object.values(groupedData)
    .sort((a, b) => new Date(a._id) - new Date(b._id))
    .map((obj) => ({
      x: new Date(obj._id),
      y: obj.totalAggression,
    }));

  const series = [
    {
      name: "Agressividade",
      data: outputTotalAggression,
    },
  ];

  var options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      locales: [
        {
          name: "pt-BR",
          options: {
            months: [
              "Janeiro",
              "Fevereiro",
              "Março",
              "Abril",
              "Maio",
              "Junho",
              "Julho",
              "Agosto",
              "Setembro",
              "Outubro",
              "Novembro",
              "Dezembro",
            ],
            shortMonths: [
              "Jan",
              "Fev",
              "Mar",
              "Abr",
              "Mai",
              "Jun",
              "Jul",
              "Ago",
              "Set",
              "Out",
              "Nov",
              "Dez",
            ],
            days: [
              "Domingo",
              "Segunda-feira",
              "Terça-feira",
              "Quarta-feira",
              "Quinta-feira",
              "Sexta-feira",
              "Sábado",
            ],
            shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
          },
        },
      ],
      defaultLocale: "pt-BR",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "MMM yyyy",
          month: "dd MMM",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },
    tooltip: {},
    yaxis: {
      show: true,
      labels: {
        formatter: function (value) {
          return value.toFixed(0);
        },
      },
    },
    colors: chartColor,
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h4 className="card-title">Agressividade Total</h4>
        </CardHeader>

        <CardBody>
          <div className="chart-container">
            <div className="chart-container__content w-100">
              {dailyTotalAggressionLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "30vh" }}
                >
                  <BouncingDotsLoader size={"24px"} />
                </div>
              ) : (
                <ReactApexChart
                  dir="ltr"
                  options={options}
                  series={series}
                  type="area"
                  height="350"
                  className="apex-charts"
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AnalyticalReportTotalAggressiveness;
