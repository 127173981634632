import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import RegisterSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import resetPasswordSaga from "./auth/resetpwd/saga";
import panelSaga from "./pages/panel/saga";
import reportSaga from "./pages/reports/saga";
import mediationSaga from "./pages/mediation/sagas";
import analyticalSaga from "./pages/analytical/sagas";
import threatSaga from "./threats/saga";
import companyProductsSaga from "./companyProducts/saga";

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(RegisterSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(resetPasswordSaga),
    fork(panelSaga),
    fork(reportSaga),
    fork(mediationSaga),
    fork(analyticalSaga),
    fork(threatSaga),
    fork(companyProductsSaga),
  ]);
}
