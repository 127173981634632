import styled from "styled-components"
import { dsVariables } from "../../variables"
import Icon from "../Icon";
import { SvgIconComponent } from "@mui/icons-material";

export type TButtonTypes = 'normal' | 'warning' | 'destructive'
export type TButtonStates = 'default' | 'disabled';

export interface IButtonProps {
    type?: TButtonTypes
    state?: TButtonStates
    label?: string
    iconLeft?: SvgIconComponent
    iconLeftColor?: string
    iconRight?: SvgIconComponent
    iconRightColor?: string
    mode?: 'light' | 'dark'
    onClick: any
    width?: number
}

export default function ButtonPrimary({ type = "normal", state = "default", label, iconLeft, iconRight, mode = "light", onClick, iconLeftColor, iconRightColor, width }: IButtonProps) {
    return (
        <Button iconRight={iconRight} style={{ width }} type="button" typeButton={type} state={state} disabled={state == "disabled"} onClick={onClick} className={`border-0 d-flex gap-2 align-items-center justify-content-between`}>
            {iconLeft && <Icon width={16} color={iconLeftColor} IconComponent={iconLeft} />}
            {label && <span className="text-center w-100">{label}</span>}
            {iconRight &&
                <DivIcon typeButton={type} className="d-flex justify-content-center align-items-center" >
                    <Icon width={16} color={iconRightColor} IconComponent={iconRight} />
                </DivIcon>
            }
        </Button>
    )
}

const DivIcon = styled.div<{ typeButton: TButtonTypes }>`
    width: ${dsVariables.spacing[36]};
    height: ${dsVariables.spacing[36]};

    background-color: ${({ typeButton }) => {
        switch (typeButton) {
            case "normal": return dsVariables.colors.Astronaut[700];
            case "destructive": return dsVariables.colors.Red[400];
            case "warning": return dsVariables.colors.Orange[500];
        }
    }};
`

const Button = styled.button<{ typeButton: TButtonTypes, state: TButtonStates, iconRight?: SvgIconComponent }>`
    padding: ${dsVariables.spacing[8]} ${props => props.iconRight ? 0 : dsVariables.spacing[12]} ${dsVariables.spacing[8]} ${dsVariables.spacing[12]};
    border-radius: ${dsVariables.cornerRadius[4]};
    height:36px;
    color: ${dsVariables.colors.Gray[50]};
    overflow:hidden;
  
    ${props => {
        if (props.state == "disabled") return `
            color: ${dsVariables.colors.Gray[300]} !important;
            border:1px solid ${dsVariables.colors.Gray[300]};
            background-color: ${dsVariables.colors.Gray[500]};
        `
      
        switch (props.typeButton) {
            case 'destructive':
                return `
                    background-color: ${dsVariables.colors.Red[700]};
                    boder: 1px solid ${dsVariables.colors.Red[700]};
                `
            case 'warning':
                return `
                    background-color: ${dsVariables.colors.Orange[600]};
                    boder: 1px solid ${dsVariables.colors.Orange[600]};
                `
            case 'normal':
            default:
                return `
                    background-color: ${dsVariables.colors.Astronaut[900]};
                `
        }
    }
    }
`