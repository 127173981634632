import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../DesignSystem/components/Modal";
import { IStore } from "../../../../interfaces/store.interface";
import { ThreatTypeEnum } from "../../../../enums/threat-type.enum";
import AdsForm from "../Forms/AdsForm";
import ProfileForm from "../Forms/ProfileForm";
import SiteForm from "../Forms/SiteForm";
import ContentForm from "../Forms/ContentForm";
import Snackbar from "../../../../DesignSystem/components/Snackbar";
import { useEffect, useState } from "react";
import { changeAdsForm, changeAdsFormId, changeAdsFormIsActive, changeAdsFormPlatform, changeAdsFormSearchDate, changeAdsFormUrl, changeContentForm, changeProfileForm, changeSiteForm } from "../../../../store/threats/actions";

interface IProps {
    isOpen: boolean
    onClose: any
    onConfirm: any
}

export default function ModalEdit({ isOpen, onClose, onConfirm }: IProps) {

    const dispatch = useDispatch()

    const { adsForm, profileForm, siteForm, contentForm, selectedCompany, formErrorMessage, formError, current } = useSelector((store: IStore) => ({
        adsForm: store.Threats.forms.adsForm,
        profileForm: store.Threats.forms.profileForm,
        siteForm: store.Threats.forms.siteForm,
        contentForm: store.Threats.forms.contentForm,
        threatsList: store.Threats.threatsList,
        selectedCompany: store.Profile.selectedCompany,
        formErrorMessage: store.Threats.forms.formErrorMessage,
        formError: store.Threats.forms.formError,
        current: store.Threats.current,
    }))

    const [showSnackInvalidUrl, setShowSnackInvalidUrl] = useState(false);
    const [showSnackUrlAlreadyExists, setShowSnackUrlAlreadyExists] = useState(false);

    const renderForm = () => {
        switch (current?.type) {
            case ThreatTypeEnum.FAKE_AD: return <AdsForm isInvalidUrl={showSnackInvalidUrl} />
            case ThreatTypeEnum.FAKE_PROFILE: return <ProfileForm isInvalidUrl={showSnackInvalidUrl} />
            case ThreatTypeEnum.FAKE_SITE: return <SiteForm isInvalidUrl={showSnackInvalidUrl} />
            case ThreatTypeEnum.FAKE_CONTENT: return <ContentForm isInvalidUrl={showSnackInvalidUrl} />
        }
    }

    const isButtonDisabled = () => {
        switch (current?.type) {
            case ThreatTypeEnum.FAKE_AD: return !(adsForm.evidenceLink || (adsForm.evidence && adsForm.evidence.length > 0))
            case ThreatTypeEnum.FAKE_PROFILE: return !(profileForm.evidenceLink || (profileForm.evidence && profileForm.evidence.length > 0))
            case ThreatTypeEnum.FAKE_SITE: return !(siteForm.evidenceLink || (siteForm.evidence && siteForm.evidence.length > 0))
            case ThreatTypeEnum.FAKE_CONTENT: return !(contentForm.evidenceLink || (contentForm.evidence && contentForm.evidence.length > 0))
        }
    }

    const getEvidenceArray = (evidence: any) => {
        if (!evidence) return []
        if (Object.hasOwn(evidence[0], 'screenshotUrl')) return evidence.map((el: any) => el.screenshotUrl)
        else return evidence
    }

    useEffect(() => {
        switch (current?.type) {
            case ThreatTypeEnum.FAKE_SITE:
                dispatch(changeSiteForm({
                    isActive: current.isActive, url: current.domain, searchDate: current.createdAt,
                    type: current.type, evidence: getEvidenceArray(current.evidence), evidenceLink: current.evidenceLink
                }))
                break;
            case ThreatTypeEnum.FAKE_AD:
                dispatch(changeAdsForm({
                    isActive: current.isActive, url: current.domain, advertiserId: current.advertiserId!,
                    advertiserName: current.advertiserName!, id: current.id!, instagramProfile: current.instagramProfile!,
                    platform: current.platform, searchDate: current.searchDate!, startDate: current.startDate!,
                    type: current.type, evidence: getEvidenceArray(current.evidence), evidenceLink: current.evidenceLink, adsQuantity: current.adsQuantity!
                }))
                break;
            case ThreatTypeEnum.FAKE_PROFILE:
                dispatch(changeProfileForm({
                    isActive: current.isActive, url: current.domain, platform: current.platform,
                    profile: current.profile!, searchDate: current.searchDate!, type: current.type,
                    evidence: getEvidenceArray(current.evidence), evidenceLink: current.evidenceLink
                }))
                break;
            case ThreatTypeEnum.FAKE_CONTENT:
                dispatch(changeContentForm({
                    isActive: current.isActive, url: current.domain, platform: current.platform,
                    profile: current.profile!, type: current.type,
                    evidence: getEvidenceArray(current.evidence), evidenceLink: current.evidenceLink
                }))
                break;
        }
    }, [current]);

    return (
        <>
            <Modal
                width={728}
                title="Incluir ameaça"
                subtitle="Preencha abaixo as informações solicitadas."
                isOpen={isOpen}
                onClose={onClose}
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: onConfirm, label: 'Salvar alterações', state: isButtonDisabled() ? 'disabled' : 'default' },
                ]}
            >
                {renderForm()}
            </Modal>
            <Snackbar setVisible={setShowSnackInvalidUrl} visible={showSnackInvalidUrl} state="error">
                Insira uma URL válida, por exemplo: https://www.url.com.br/
            </Snackbar>

            <Snackbar setVisible={setShowSnackUrlAlreadyExists} visible={showSnackUrlAlreadyExists} state="error">
                Já existe uma ameaça registrada com a URL informada.
            </Snackbar>
        </>
    )
}