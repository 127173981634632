import { fork, put, all, call, takeLatest, take } from "redux-saga/effects";
import { GET_USER_CAMPAIGNS, SET_USER_PROFILE } from "./actionTypes";
import { profileError, setSelectedCampaign, setSelectedCompany, setUserCampaigns, setUserLoggedIn, getUserCampaigns } from "./actions";

import {
  getUserCampaign, getUserCompanies, getUserProfile,
} from "../../../helpers/backend_helper";
import { removePanelLoading, setPanelLoading } from "../../actions";

function* setUserProfile() {
  try {
    const loggedUser = yield call(getUserProfile);
    yield put(setUserLoggedIn(loggedUser))

    yield put(setPanelLoading())

    const userCompanies = yield call(getUserCompanies)
    loggedUser.companies = userCompanies.map((company) => ({ _id: company._id, name: company.name, createdAt: company.createdAt, accessProducts: company.accessProducts }))

    let companyStorage = localStorage.getItem('bm-platform')

    companyStorage = JSON.parse(JSON.parse(JSON.parse(JSON.stringify(companyStorage))).Profile).selectedCompany

    yield put(setSelectedCompany({ value: companyStorage.value ? companyStorage.value : loggedUser.companies[0]._id, label: companyStorage.label ? companyStorage.label : loggedUser.companies[0].name }))

    yield put(removePanelLoading())
  } catch {
    yield put(profileError());
  }
}


function* getUserCampaignsByCompany({ payload: { selectedCompany } }) {
  try {
    yield put(setPanelLoading())

    const campaigns = yield call(getUserCampaign, { companyId: selectedCompany.value })
    yield put(setUserCampaigns(campaigns.map((campaign) => ({ _id: campaign._id, name: campaign.name, createdAt: campaign.createdAt, keywords: campaign.keywords, urlLogo: campaign.urlLogo, pages: campaign.pages }))))
    yield put(setSelectedCampaign({ value: campaigns[0]._id, label: campaigns[0].name, createdAt: campaigns[0].createdAt, keywords: campaigns[0].keywords, urlLogo: campaigns[0].urlLogo, pages: campaigns[0].pages }))

    yield put(removePanelLoading())
  } catch {
    yield put(profileError());
  }
}
export function* watchProfile() {
  yield takeLatest(GET_USER_CAMPAIGNS, getUserCampaignsByCompany);
  yield takeLatest(SET_USER_PROFILE, setUserProfile);

}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
