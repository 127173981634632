import { DeleteOutline, WarningAmber } from "@mui/icons-material";
import Modal from "../../../../DesignSystem/components/Modal";
import Icon from "../../../../DesignSystem/components/Icon";
import { dsVariables } from "../../../../DesignSystem/variables";
import { IStore } from "../../../../interfaces/store.interface";
import { useSelector } from "react-redux";
import Body from "../../../../DesignSystem/components/Text/Body";

interface IProps {
    isOpen: boolean
    onClose: any
    onConfirm: any
}

export default function ModalDiscard({ isOpen, onClose, onConfirm }: IProps) {
    const { current, currentIds } = useSelector((store: IStore) => store.Threats)

    return (
        <Modal
            title="Descartar" subtitle={currentIds.length > 0 ? `Ameaças: ${currentIds.length}` : `Ameaça: ${current?.domain}`} isOpen={isOpen} onClose={onClose}
            width={584}
            footerButtons={[
                { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                { buttonStyle: 'primary', onClick: onConfirm, label: 'Descartar', type: 'destructive', iconLeft: DeleteOutline, iconLeftColor: 'white' },
            ]}
        >
            <div className="d-flex flex-column gap-3 justify-content-center align-items-center text-center">
                <Icon width={88} height={88} IconComponent={WarningAmber} color={dsVariables.colors.Orange[600]} />
                {currentIds.length > 0 ?
                    <Body color={dsVariables.colors.Gray[700]}>
                        Ao realizar esta ação, as ameaças
                        <Body style="medium">&nbsp;serão removidas&nbsp;</Body>
                        da sua lista. A ação poderá ser desfeita ao
                        <Body style="medium">&nbsp;Restaurar&nbsp;</Body>
                        as ameaças da lista na aba
                        <Body>&nbsp;Descartadas</Body>.
                    </Body>
                    : <Body color={dsVariables.colors.Gray[700]}>
                        Ao realizar esta ação, a ameaça
                        <Body style="medium">&nbsp;será removida&nbsp;</Body>
                        da sua lista. A ação poderá ser desfeita ao
                        <Body style="medium">&nbsp;Restaurar&nbsp;</Body>
                        a ameaça da lista na aba
                        <Body>&nbsp;Descartadas</Body>.
                    </Body>}
            </div>
        </Modal>
    )
}

