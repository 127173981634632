import { useCallback, useEffect, useState } from "react";
import Modal from "../../../../DesignSystem/components/Modal";
import AdsForm from "../Forms/AdsForm";
import { ThreatTypeEnum } from "../../../../enums/threat-type.enum";
import SiteForm from "../Forms/SiteForm";
import ProfileForm from "../Forms/ProfileForm";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { changeFraudsFormError, cleanForms, registerFraud, setLoading } from "../../../../store/threats/actions";
import { ThreatPlatformEnum } from "../../../../enums/threat-platform.enum";
import { useLocation, useNavigate } from "react-router-dom";
import ContentForm from "../Forms/ContentForm";
import { ThreatTypeTextEnum } from "../../../../enums/threat-type-text.enum";
import { isUrlValid } from "../../../../DesignSystem/utils/is-url-valid.util";
import Snackbar from "../../../../DesignSystem/components/Snackbar";

interface IProps {
    isOpen: boolean
    onClose: any
    setShowSnackNewThreat: any
}

export default function ModalNewThreats({ isOpen, onClose, setShowSnackNewThreat }: IProps) {
    const dispatch = useDispatch()

    const [typeForm, setTypeForm] = useState<ThreatTypeTextEnum>(ThreatTypeTextEnum.FAKE_AD);
    const [showSnackInvalidUrl, setShowSnackInvalidUrl] = useState(false);
    const [showSnackUrlAlreadyExists, setShowSnackUrlAlreadyExists] = useState(false);

    const { adsForm, profileForm, siteForm, contentForm, selectedCompany, formErrorMessage, formError } = useSelector((store: IStore) => ({
        adsForm: store.Threats.forms.adsForm,
        profileForm: store.Threats.forms.profileForm,
        siteForm: store.Threats.forms.siteForm,
        contentForm: store.Threats.forms.contentForm,
        threatsList: store.Threats.threatsList,
        selectedCompany: store.Profile.selectedCompany,
        formErrorMessage: store.Threats.forms.formErrorMessage,
        formError: store.Threats.forms.formError,
    }))

    useEffect(() => {
        if (formError) {
            setShowSnackUrlAlreadyExists(true)
            dispatch(changeFraudsFormError(undefined))
        }
        else if (typeof formError != 'undefined') {
            onClose()
            setShowSnackNewThreat(true)
            dispatch(changeFraudsFormError(undefined))
        }
    }, [formErrorMessage, formError]);

    const isButtonDisabled = () => {
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD: return !Object.entries(adsForm)
                .filter(([key]) => key !== 'isActive' && key !== 'evidence' && key !== 'evidenceLink' && key !== 'id'
                    && key !== 'advertiserName' && key !== 'advertiserId' && key !== 'instagramProfile')
                .every(([, value]) => Boolean(value)) || !(adsForm.evidence!?.length > 0 || Boolean(adsForm.evidenceLink))
            case ThreatTypeTextEnum.FAKE_PROFILE: return !Object.entries(profileForm)
                .filter(([key]) => key !== 'isActive' && key !== 'evidence' && key !== 'evidenceLink')
                .every(([, value]) => Boolean(value)) || !(profileForm.evidence!?.length > 0 || Boolean(profileForm.evidenceLink))
            case ThreatTypeTextEnum.FAKE_SITE: return !Object.entries(siteForm)
                .filter(([key]) => key !== 'isActive' && key !== 'evidence' && key !== 'evidenceLink')
                .every(([, value]) => Boolean(value)) || !(siteForm.evidence!?.length > 0 || Boolean(siteForm.evidenceLink))
            case ThreatTypeTextEnum.FAKE_CONTENT: return !Object.entries(contentForm)
                .filter(([key]) => key !== 'isActive' && key !== 'evidence' && key !== 'evidenceLink')
                .every(([, value]) => Boolean(value)) || !(contentForm.evidence!?.length > 0 || Boolean(contentForm.evidenceLink))
        }
    }

    function changeTypeForm(threatTypeText: ThreatTypeTextEnum) {
        dispatch(cleanForms())
        setTypeForm(threatTypeText)
    }

    const renderForm = useCallback(() => {
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD: return <AdsForm isInvalidUrl={showSnackInvalidUrl} setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_PROFILE: return <ProfileForm isInvalidUrl={showSnackInvalidUrl} setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_SITE: return <SiteForm isInvalidUrl={showSnackInvalidUrl} setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_CONTENT: return <ContentForm isInvalidUrl={showSnackInvalidUrl} setTypeForm={changeTypeForm} />
        }
    }, [typeForm, showSnackInvalidUrl])

    async function registerThreat() {
        dispatch(setLoading(true))
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD:
                if (!isUrlValid(adsForm.url)) {
                    setShowSnackInvalidUrl(true)
                    return dispatch(setLoading(false))
                }

                dispatch(registerFraud({
                    platform: adsForm.platform, type: ThreatTypeEnum.FAKE_AD,
                    domain: adsForm.url, isActive: adsForm.isActive, evidence: adsForm.evidence,
                    companyId: selectedCompany?.value!, evidenceLink: adsForm.evidenceLink,
                    advertiserName: adsForm.advertiserName, advertiserId: adsForm.advertiserId,
                    id: adsForm.id, instagramProfile: adsForm.instagramProfile,
                    searchDate: new Date(adsForm.searchDate), startDate: new Date(adsForm.startDate),
                    adsQuantity: adsForm.adsQuantity
                }))
                break;
            case ThreatTypeTextEnum.FAKE_PROFILE:
                if (!isUrlValid(profileForm.url)) {
                    setShowSnackInvalidUrl(true)
                    return dispatch(setLoading(false))
                }

                dispatch(registerFraud({
                    platform: profileForm.platform, type: ThreatTypeEnum.FAKE_PROFILE,
                    domain: profileForm.url, isActive: profileForm.isActive, evidence: profileForm.evidence,
                    companyId: selectedCompany?.value!, evidenceLink: profileForm.evidenceLink,
                    profile: profileForm.profile, searchDate: new Date(profileForm.searchDate)
                }))
                break;
            case ThreatTypeTextEnum.FAKE_SITE:
                if (!isUrlValid(siteForm.url)) {
                    setShowSnackInvalidUrl(true)
                    return dispatch(setLoading(false))
                }

                dispatch(registerFraud({
                    platform: ThreatPlatformEnum.EMPTY, type: ThreatTypeEnum.FAKE_SITE,
                    domain: siteForm.url, searchDate: new Date(siteForm.searchDate),
                    isActive: siteForm.isActive, evidence: siteForm.evidence,
                    companyId: selectedCompany?.value!, evidenceLink: siteForm.evidenceLink
                }))

                break;
            case ThreatTypeTextEnum.FAKE_CONTENT:
                if (!isUrlValid(contentForm.url)) {
                    setShowSnackInvalidUrl(true)
                    return dispatch(setLoading(false))
                }

                dispatch(registerFraud({
                    platform: contentForm.platform, type: ThreatTypeEnum.FAKE_CONTENT,
                    domain: contentForm.url, isActive: contentForm.isActive, evidence: contentForm.evidence,
                    companyId: selectedCompany?.value!, evidenceLink: contentForm.evidenceLink,
                    profile: contentForm.profile
                }))
                break;
        }
    }

    return (
        <>
            <Modal
                width={728}
                title="Incluir ameaça"
                subtitle="Preencha abaixo as informações solicitadas."
                isOpen={isOpen}
                onClose={onClose}
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: registerThreat, label: 'Inserir', state: isButtonDisabled() ? 'disabled' : 'default' },
                ]}
            >
                {renderForm()}
            </Modal>
            <Snackbar setVisible={setShowSnackInvalidUrl} visible={showSnackInvalidUrl} state="error">
                Insira uma URL válida, por exemplo: https://www.url.com.br/
            </Snackbar>

            <Snackbar setVisible={setShowSnackUrlAlreadyExists} visible={showSnackUrlAlreadyExists} state="error">
                Já existe uma ameaça registrada com a URL informada.
            </Snackbar>
        </>

    );
}
