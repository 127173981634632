import Cell from "./Cell"
import Column from "./Column"
import styled from "styled-components"
import { dsVariables } from "../../../variables"
import { useSearchParams } from "react-router-dom"
import { InfoOutlined, MoreVertRounded } from "@mui/icons-material"
import { ThreatOptionsCardEnum } from "../../../../enums/threat-options-card.enum"
import EmptyState from "./EmptyState"
import { useDispatch, useSelector } from "react-redux"
import { IStore } from "../../../../interfaces/store.interface"
import { changeThreatsCurrent, changeThreatsCurrentIds, changeThreatsTable, updateFraud } from "../../../../store/threats/actions"
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum"
import { getThreatTypeText } from "../../../utils/get-threat-type-text.util"
import { ThreatStateEnum } from "../../../../enums/threat-state.enum"
import Paginator from "./Paginator"

interface IProps {
    showDetails: any
    showOptions: any
}

export default function TableThreats({ showDetails, showOptions }: IProps) {

    const searchParams = useSearchParams()[0]
    const isTabOne = searchParams.get('tab') == '2'
    const tabActive = searchParams.get('tab')

    const { threatsTableData, currentIds, threatsList, showAllCompanies } = useSelector((store: IStore) => {
        return {
            threatsTableData: store.Threats.threatsTableData,
            currentIds: store.Threats.currentIds,
            threatsList: store.Threats.threatsList,
            showAllCompanies: store.Profile.user.showAllCompanies
        }
    })

    const dispatch = useDispatch()

    function handleCheckboxColumn(checked: boolean) {
        if (checked) {
            dispatch(changeThreatsCurrentIds(threatsTableData.map((el: any) => el._id)))
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => ({ ...threat, checked: true }))))
        }
        else {
            dispatch(changeThreatsCurrentIds([]))
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => ({ ...threat, checked: false }))))
        }
    }

    function handleCheckboxRow(checked: boolean, id: string) {
        if (checked) {
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => {
                if (threat._id == id) return { ...threat, checked: true }
                else return threat
            })))
            dispatch(changeThreatsCurrentIds([...currentIds, id]))
            dispatch(changeThreatsCurrent(threatsList.find(el => el._id == id)!))
        } else {
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => {
                if (threat._id == id) return { ...threat, checked: false }
                else return threat
            })))
            const updatedArray = currentIds.filter(el => el != id)

            dispatch(changeThreatsCurrentIds(updatedArray))
        }
    }

    return (
        <>
            <Div isEmpty={threatsTableData.length == 0} className="table-responsive h-100">
                <table className="table">
                    <thead>
                        <TR>
                            <Column checkboxDisabled={!showAllCompanies ? tabActive != '2' : tabActive == '5'} showCheckbox={true} onChangeCheckbox={(checked: boolean) => handleCheckboxColumn(checked)} />
                            <Column label="URL" />
                            <Column label="Identificado" />
                            <Column label="Última análise" />
                            <Column label="Plataforma" />
                            <Column label="Tipos de Ameaça" />
                            <Column label="Status" />
                            <Column />
                        </TR>
                    </thead>
                    <Tbody isTabOne={isTabOne}>
                        {threatsTableData?.map((threat, index) =>
                            <tr role="button" key={threat._id}>
                                <Cell checkboxDisabled={!showAllCompanies ? tabActive != '2' : tabActive == '5'} checked={threat.checked || Boolean(currentIds.find(el => el == threat._id))} showCheckbox={true} onChangeCheckbox={(checked: boolean) => handleCheckboxRow(checked, threat._id!)} />
                                <Cell isLink label={threat.domain} />
                                <Cell label={new Date(threat.createdAt).toLocaleDateString('pt-BR')} />
                                <Cell label={threat.updatedAt ? new Date(threat.updatedAt).toLocaleDateString('pt-BR') : '—'} />
                                <Cell isTag label={threat.platform} />
                                <Cell label={getThreatTypeText(threat.type)} />
                                {
                                    !showAllCompanies
                                        ? <Cell tagType={threat.isActive ? "success" : "negative"} isTag label={threat.isActive ? ThreatStatusActiveEnum.ACTIVE : ThreatStatusActiveEnum.INACTIVE} />
                                        : <Cell onChangeSwitch={(checked: boolean) => {
                                            if (!checked) {
                                                // if (tabActive == '2') return dispatch(updateFraud({ ...threat, isActive: checked, currentState: ThreatStateEnum.DISCARDED }))
                                                // if (tabActive == '4') return dispatch(updateFraud({ ...threat, isActive: checked, currentState: ThreatStateEnum.RESOLVED }))
                                                if (tabActive == '2') return dispatch(updateFraud({ ...threat, isActive: checked }))
                                                if (tabActive == '4') return dispatch(updateFraud({ ...threat, isActive: checked }))
                                            }
                                            dispatch(updateFraud({ ...threat, isActive: checked }))
                                        }} isSwitch switchState={tabActive == '5' ? 'disabled' : 'default'} switchIsOn={threat.isActive} />
                                }
                                {
                                    !showAllCompanies && tabActive == '4' ? <Cell label="" />
                                       : <Cell Icons={
                                            tabActive != '5' ?
                                                [
                                                    // { Icon: InfoOutlined, color: dsVariables.colors.Astronaut[900], onClick: () => showDetails(threat._id), tooltipText: 'Ver detalhes' },
                                                    { Icon: MoreVertRounded, color: dsVariables.colors.Astronaut[900], onClick: (option: ThreatOptionsCardEnum) => showOptions(threat._id, option), tooltipText: 'Mais opções',tableIndex:index }
                                                ]
                                                : [
                                                    // { Icon: InfoOutlined, color: dsVariables.colors.Astronaut[900], onClick: () => showDetails(threat._id), tooltipText: 'Ver detalhes' },
                                                ]
                                        } />
                                }

                            </tr>
                        )}
                    </Tbody>
                </table>
            </Div>

            {threatsTableData?.length == 0 && <EmptyState text="Nenhuma ameaça listada" />}

            <Paginator />
        </>
    )
}

const Div = styled.div<{isEmpty: boolean}>`
    &::-webkit-scrollbar {
        display:none;
    }
    min-height:${props => props.isEmpty ? 0 : '500px'};
    overflow-y:hidden;
`

const TR = styled.tr`
    background: ${dsVariables.colors.Whitesmoke[50]};   
    font-family: ${dsVariables.fontFamilies.Inter};
    font-size:12px;

    th{
        color: ${dsVariables.colors.Gray[900]};
    }

    th:nth-child(2){
        width: 360px;
    }

    th:nth-child(n+3){
        width: 116px;
    }

    th:first-child{
        width: 32px;
    }
`

const Tbody = styled.tbody<{ isTabOne: boolean }>`
    tr {
        background: ${dsVariables.colors.Gray[50]};
        font-family: ${dsVariables.fontFamilies.Inter};

        &:hover {
            background-color: ${dsVariables.colors.Gray[100]};
        }

        td:nth-child(2) {
            color: ${dsVariables.colors.Blue[400]} !important;
            
            label {
                text-decoration: underline;
            }
        }

        td:nth-child(2) {
            ${props => props.isTabOne &&
        `   
                    color: ${dsVariables.colors.Blue[400]} !important;
                `
    }
                    
            label {
                ${props => props.isTabOne &&
        `   
                    text-decoration: underline;
                    `
    }
            }
        }

        td {
            color: ${dsVariables.colors.Gray[800]};
        }
    }
`