import { ContentCopy } from "@mui/icons-material";
import Modal from "../../../../DesignSystem/components/Modal";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import Body from "../../../../DesignSystem/components/Text/Body";
import { dsVariables } from "../../../../DesignSystem/variables";
import { useSearchParams } from "react-router-dom";

interface IProps {
    isOpen: boolean
    onClose: any
    onConfirm: any
}

export default function ModalQuarantine({ isOpen, onClose, onConfirm }: IProps) {

    const { current, currentIds } = useSelector((store: IStore) => store.Threats)

    const searchParams = useSearchParams()[0]
    const tabActive = searchParams.get('tab')

    return (
        <Modal
            title="Quarentena" subtitle={currentIds.length > 0 ? `Ameaças: ${currentIds.length}` : `Ameaça: ${current?.domain}`} isOpen={isOpen} onClose={onClose}
            width={552}
            footerButtons={[
                { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                { buttonStyle: 'primary', onClick: onConfirm, label: 'Confirmar' },
            ]}
        >
            {currentIds.length > 0
                ? <>
                    <Body color={dsVariables.colors.Gray[800]} style="medium">Deseja realmente mover essas ameaças para quarentena?</Body>.
                    <br />
                    <Body color={dsVariables.colors.Gray[800]}>Ao fazer isso, as ameaças serão retiradas da lista na aba de <Body style="medium">{tabActive == '0' ? 'Novas' : 'Descartadas'}</Body> e irão para a lista na aba <Body style="medium">Quarentena</Body>, confirme abaixo para efetivar a ação.</Body>
                    <br />
                    <br />
                </>
                : <>
                    <Body color={dsVariables.colors.Gray[800]} style="medium">Deseja realmente mover essa ameaça para quarentena?</Body>.
                    <br />
                    <Body color={dsVariables.colors.Gray[800]}>Ao fazer isso, a ameaça será retirada da lista na aba de <Body style="medium">{tabActive == '0' ? 'Novas' : 'Descartadas'}</Body> e irá para a lista <Body style="medium">Quarentena</Body>, confirme abaixo para efetivar a ação.</Body>
                    <br />
                    <br />
                </>
            }
        </Modal>
    )
}