import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TicketDetailsSession from "./TicketDetailsSession";
import TicketDetailsRegisterAggressor from "./TicketDetailsRegisterAggressor";
import TicketDetails from "./TicketDetails";
import TicketDetailsModalAdverts from "./TicketDetailsModalAdverts";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEventEvidence,
  getEvent as fetchEvent,
  getEventAds as fetchEventAds,
  getUserCampaigns,
  getEventDetails as fetchEventDetails, // Verificar
} from "../../../../store/actions";
import BouncingDotsLoader from "../../../../Components/Common/BoucingDotsLoader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { number } from "echarts"; // Verificar

const ReportTicketDetails = () => {
  document.description =
    "Combata o uso indevido da sua marca e maximize seus resultados";
  const [modalAdverts, setModalAdverts] = useState(false);
  const toggleModal = () => setModalAdverts(!modalAdverts);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const eventId = query.get("eventId");
  const aggressivenessIndex = query.get("aggressivenessIndex");

  const {
    event,
    ads,
    getEvent,
    getEventAds,
    patchEventMediation,
    patchEventEvidenceUpload,
  } = useSelector((state) => state.Mediation);

  const { loading: eventLoading, error: eventError } = getEvent;
  const { loading: adsLoading, error: adsError } = getEventAds;
  const { error: deleteEvidenceError } = deleteEventEvidence;
  const { loading: updateLoading, error: errorUpdate } = patchEventMediation;
  const { loading: evidenceUploadLoading, error: errorUpload } =
    patchEventEvidenceUpload;
  const { selectedEvent } = useSelector((state) => state.Profile);
  const { selectedCompany, selectedCampaign, user } = useSelector(
    (state) => state.Profile
  );
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    navigate("/reports");
    dispatch(getUserCampaigns(selectedCompany));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, selectedCompany]);

  const ticketId = query.get("ticketId");
  const campaignId = query.get("campaignId");
  const aggressivenessNumber = Number(aggressivenessIndex); // Verificar

  useEffect(() => {
    dispatch(fetchEvent(ticketId || selectedEvent));
  }, [dispatch, ticketId, selectedEvent, campaignId]);

  useEffect(() => {
    if (event.domain) {
      document.title = `Branddi.com - BrandBidding | Detalhes do Agressor - ${event.domain}`;
    }
  }, [event]);

  const handleAds = (campaignId, domain) => {
    dispatch(fetchEventAds(campaignId, domain));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Detalhes do Ticket" pageTitle="Brand Bidding" />
          <BreadCrumb
            path={[
              { title: "Brand Bidding", link: "/" },
              { title: "Gerenciar Agressores", link: "/reports" },
              { title: "Detalhes do Ticket", link: "" },
            ]}
          />
          {eventLoading ? (
            <div
              style={{ minHeight: "90vh" }}
              className="d-flex align-items-center justify-content-center"
            >
              <BouncingDotsLoader />
            </div>
          ) : eventError ? (
            <div
              style={{ minHeight: "90vh" }}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <h3>Erro ao carregar ticket.</h3>
              <p> Por favor, entre em contato com nossa equipe de suporte.</p>
              <Link to="/reports">Voltar</Link>
            </div>
          ) : (
            <React.Fragment>
              <TicketDetailsSession
                eventData={event}
                handleToggle={() => toggleModal()}
                handleAds={() => handleAds(event.campaignId, event.domain)}
                deleteEventEvidenceError={deleteEvidenceError}
              />
              <Row>
                <TicketDetailsRegisterAggressor
                  eventData={event}
                  updateLoading={updateLoading}
                  evidenceUploadLoading={evidenceUploadLoading}
                  errorUpdate={errorUpdate}
                  errorUpload={errorUpload}
                />
                <TicketDetails
                  eventData={event}
                  handleToggle={() => toggleModal()}
                  handleAds={() => handleAds(event.campaignId, event.domain)}
                />
              </Row>
            </React.Fragment>
          )}
        </Container>
      </div>

      <TicketDetailsModalAdverts
        ads={ads}
        isOpen={modalAdverts}
        toggle={toggleModal}
        loading={adsLoading}
        error={adsError}
      />
    </React.Fragment>
  );
};

export default ReportTicketDetails;
