import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { Card, CardBody, CardHeader, UncontrolledTooltip } from "reactstrap";
import BouncingDotsLoader from "../../../Components/Common/BoucingDotsLoader";
import { Info } from "feather-icons-react/build/IconComponents";

const AnalyticalReportOccurrences = ({
  occurrencesTrendLoading,
  dailyScoreData,
  startDate,
  endDate,
}) => {
  var chartColor = getChartColorsArray(
    '["--vz-secondary", "--vz-card-custom"]'
    
  );

  const groupedData = dailyScoreData.reduce((acc, doc) => {
    if (doc.processedDate) {
      const date = new Date(doc.processedDate);
      if (!isNaN(date)) {
        const dateString = date.toISOString().split("T")[0];
        if (!acc[dateString]) {
          acc[dateString] = doc.totalOccurrences;
        } else {
          acc[dateString] += doc.totalOccurrences;
        }
      }
    }
    return acc;
  }, {});

  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  const allDates = getDates(new Date(startDate), new Date(endDate));

  allDates.forEach((date) => {
    const dateString = date.toISOString().split("T")[0];
    if (!groupedData[dateString]) {
      groupedData[dateString] = 0;
    }
  });

  const sortedDates = Object.keys(groupedData).sort();

  const dates = [];
  const occurrences = [];
  sortedDates.forEach((date) => {
    dates.push(date);
    occurrences.push(groupedData[date]);
  });

  const maxOccurrences = Math.max(...occurrences);

  const normalizedOccurrences = occurrences.map((value) =>
    Math.round((value / maxOccurrences) * 100)
  );

  const series = [
    {
      name: "Ocorrências",
      data: normalizedOccurrences,
    },
  ];

  var options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      locales: [
        {
          name: "pt-BR",
          options: {
            months: [
              "Janeiro",
              "Fevereiro",
              "Março",
              "Abril",
              "Maio",
              "Junho",
              "Julho",
              "Agosto",
              "Setembro",
              "Outubro",
              "Novembro",
              "Dezembro",
            ],
            shortMonths: [
              "Jan",
              "Fev",
              "Mar",
              "Abr",
              "Mai",
              "Jun",
              "Jul",
              "Ago",
              "Set",
              "Out",
              "Nov",
              "Dez",
            ],
            days: [
              "Domingo",
              "Segunda-feira",
              "Terça-feira",
              "Quarta-feira",
              "Quinta-feira",
              "Sexta-feira",
              "Sábado",
            ],
            shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
          },
        },
      ],
      defaultLocale: "pt-BR",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    labels: dates || [],
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "MMM yyyy",
          month: "dd MMM",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },
    yaxis: {
      show: true,
      min: 0,
      max: 100,
    },
    colors: chartColor,
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="d-flex gap-2 align-items-center">
          <h4 className="card-title m-0">Ocorrências Trends</h4>
          <Info id="OccurrencesTrendsTooltip" className="ml-2" />

          <UncontrolledTooltip
            placement="top"
            target="OccurrencesTrendsTooltip"
            className="text-left"
          >
            Os números representam as ocorrências relativas ao ponto mais alto
            no gráfico de uma determinada região em um dado período. Um valor de
            100 representa o pico de ocorrências. Um valor de 50 significa que
            tivemos metade das ocorrências. Uma pontuação de 0 significa que não
            haviam ocorrências.
          </UncontrolledTooltip>
        </CardHeader>

        <CardBody>
          <div className="chart-container">
            <div className="chart-container__content w-100">
              {occurrencesTrendLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "30vh" }}
                >
                  <BouncingDotsLoader size={"24px"} />
                </div>
              ) : (
                <ReactApexChart
                  dir="ltr"
                  options={options}
                  series={series}
                  type="area"
                  height={350}
                  className="apex-charts"
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AnalyticalReportOccurrences;
